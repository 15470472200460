<template>
  <div class="home-lojas">
    <mf-loading-dialog :loading="$apollo.loading">
      <v-container class="pa-0" fluid>
        <v-row v-if="!isFranchise" justify="end">
          <v-col cols="auto">
            <mf-button label="Adicionar Loja" @click="triggerAddUnit"></mf-button>
          </v-col>
        </v-row>

        <v-expansion-panels v-model="panel">
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header>
              <span class="card-title">
                <v-icon class="mr-1 mb-1">mdi-store</v-icon>
                Lojas Ativas
              </span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <sortable-fields-list v-if="units" v-model="units" :ordering="false" :remove-callback="removeUnit">
                <template v-slot:label="{ field }">
                  <span
                    ><b>{{ field.id_erp }}</b> - {{ field.name }}</span
                  >
                </template>
                <template v-slot:editor="slotProps">
                  <edit-unit
                    admin-view
                    :dbName="dbName"
                    :value="slotProps.editing"
                    :unit="slotProps.selectedField"
                    :saveCallback="saveSelectedUnit"
                    :client-units="clientUnits"
                    :is-franchise="isFranchise"
                    @close="slotProps.closeHandler"
                  />
                </template>
              </sortable-fields-list>
              <edit-unit
                v-model="adding"
                :dbName="dbName"
                admin-view
                is-new
                :client-units="clientUnits"
                :unit="newUnit"
                :saveCallback="addUnit"
                @close="adding = false"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels>
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header>
              <span class="card-title">
                <v-icon class="mr-1 mb-2">mdi-store-off</v-icon>
                Lojas Inativas
              </span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <sortable-fields-list v-if="inactiveUnits" v-model="inactiveUnits" :ordering="false" :remove-callback="removeUnit">
                <template v-slot:label="{ field }">
                  <span
                    ><b>{{ field.id_erp }}</b> - {{ field.name }}</span
                  >
                </template>
                <template v-slot:editor="slotProps">
                  <edit-unit
                    admin-view
                    :value="slotProps.editing"
                    :unit="slotProps.selectedField"
                    :saveCallback="saveSelectedUnit"
                    :is-franchise="isFranchise"
                    :client-units="clientUnits"
                    @close="slotProps.closeHandler"
                  />
                </template>
              </sortable-fields-list>
              <edit-unit
                v-model="adding"
                :dbName="dbName"
                admin-view
                is-new
                :unit="newUnit"
                :client-units="clientUnits"
                :saveCallback="addUnit"
                @close="adding = false"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </mf-loading-dialog>
  </div>
</template>

<script>
import { MUTATION_ADD_UNIT, MUTATION_REMOVE_UNIT, MUTATION_UNIT, QUERY_UNITS, QUERY_CLIENT_UNITS, QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'
import { deepDelete } from '@/helpers/deepDelete'

export default {
  name: 'Units',
  components: {
    SortableFieldsList: () => import('@/modules/accounts/components/units/SortableFieldsList.vue'),
    EditUnit: () => import('@/modules/accounts/components/units/EditUnit.vue')
  },
  data: () => ({
    adding: false,
    newUnit: {},
    accountId: '',
    inactiveUnits: [],
    dbName: '',
    panel: 0,
    clientId: '',
    clientUnits: null
  }),
  computed: {
    isFranchise() {
      return localStorage.getItem('is_franchise') === 'true'
    }
  },
  apollo: {
    units: {
      query: QUERY_UNITS,
      fetchPolicy: 'network-only',
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      update(data) {
        const units = data.units.map(unit => {
          if (!unit.active) this.inactiveUnits.push(unit)
          else return unit
        })

        return units.filter(unit => unit !== undefined)
      }
    },
    getClientUnits: {
      query: QUERY_CLIENT_UNITS,
      fetchPolicy: 'network-only',
      skip() {
        return !this.clientId
      },
      variables() {
        return { client_units: { client_id: this.clientId } }
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      update(data) {
        console.log('received', data.clientUnits)
        this.clientUnits = data.clientUnits
        console.log('wtf', this.clientUnits)
      }
    },
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      update(data) {
        if (data?.adminAccount?.franchise_id) {
          this.dbName = data?.adminAccount?.db_name
        }
        this.clientId = data?.adminAccount?.client_id
      }
    }
  },
  mounted() {
    document.title = 'Configurações de Lojas'
    this.accountId = this.$route.params.id
  },
  methods: {
    triggerAddUnit() {
      this.newUnit = {}
      this.adding = true
    },
    async addUnit(unit) {
      deepDelete(unit, '__typename')
      await this.$apollo.mutate({
        mutation: MUTATION_ADD_UNIT,
        variables: unit,
        context: {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountId: this.accountId
          },
          uri: this.$microservicesUrls['crm']
        }
      })
      this.adding = false
      this.inactiveUnits = []
      this.$apollo.queries.units.refetch()
    },
    async removeUnit(unit) {
      deepDelete(unit, '__typename')
      await this.$apollo.mutate({
        mutation: MUTATION_REMOVE_UNIT,
        variables: {
          unit_id: unit._id
        },
        context: {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['crm']
        }
      })
      this.inactiveUnits = []
      this.$apollo.queries.units.refetch()
    },
    async saveSelectedUnit(unit, unitId, account = undefined) {
      deepDelete(unit, '__typename')
      await this.$apollo.mutate({
        mutation: MUTATION_UNIT,
        variables: {
          ...unit,
          unit_id: unitId
        },
        context: {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: account || this.accountId
          },
          uri: this.$microservicesUrls['crm']
        }
      })
      this.inactiveUnits = []
      this.$apollo.queries.units.refetch()
    }
  }
}
</script>

<style lang="scss">
.home-lojas {
  .buttons-row {
    padding: 5px 30px 5px 30px;
  }
  .section-title {
    font-size: 20px;
    font-weight: bold;
  }
  .detail-title {
    opacity: 0.8;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.24px;
    color: #000000;
    margin-bottom: 8px;
  }
  .color-box {
    width: 24px;
    height: 24px;
    border: 1px solid #515151;
    border-radius: 4px;
    text-align: center;
    span {
      color: #515151;
      font-weight: bold;
    }
  }
  .alert-chip {
    display: block;
    max-width: 100px;
  }
}
</style>
